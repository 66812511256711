
import { getJobBySlug } from "@/services/v2/job.service"
import { routeToJob } from "@/helpers/routing"

import Button from "@/components/base/Button"
import JobCardFlat from "@/components/job/card/JobCardFlat"

export default {
    name: "LivestreamJobOverview",
    components: {
        Button,
        JobCardFlat,
    },
    props: {
        business: {
            type: Object,
            default: null,
        },
    },
    async fetch() {
        if (this.business?.published_jobs?.length < 1) {
            this.fullJobs = []
        }
        const promisses = this.business.published_jobs.map(
            (job) => this.$api.run(getJobBySlug, job.slug).request
        )
        this.fullJobs = await Promise.all(promisses)
    },
    data: () => ({
        routeToJob,
        fullJobs: null,
    }),
    computed: {
        heroImage() {
            if (this.business.main_image) {
                return this.$getImage({
                    image: this.business.main_image,
                    preferedSize: 2,
                }).src
            }
            return require("@/assets/images/job/illustration_saved_prompt_profile_unlock.jpg")
        },
    },
    methods: {
        onJobClick(job) {
            this.onClose()
            this.$router.push(routeToJob(job))
        },
        onClose() {
            this.$store.dispatch("ui/clearGlobalComponent")
        },
    },
}
